import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const jaydeepExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Jaydeep Sangani",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/jaydeep-sangani.png',
    tag: `jaydeep-sangani`,
    phones: ['(+91) 9904648243'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['jaydeep@vasundhara.io'],
    linkedin: 'https://www.linkedin.com/in/jaydeep-sangani-64503b124',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+919904648243`,
    description: [
        `An experienced project manager at Vasundhara Infotech, instrumental in driving the company's growth through effective leadership.`,
        `Possesses a profound understanding of business dynamics, positively impacting company projects by aligning them with strategic objectives.`,
        `Committed to maintaining transparency and efficiency, Mr. Sangani consistently informs project progress to team members and encourages continuous learning.`,
        `Masterful in communication, he actively engages with clients and team members at every project stage, ensuring timely and deadline-driven project completion.`,
        `Expertise in managing and leading multi-functional teams, reducing anxiety around uncertainty and enhancing project outcomes.`,
        `Demonstrates a flexible approach by aligning with changing strategic objectives, promoting adaptability through transparent communication.`,
        `Diligently monitors the development process, proactively addressing issues encountered during development and testing, ensuring seamless project execution.`,
    ]
}

function JaydeepSanghani() {

    const tabNameData = ['Game Development'];
        useEffect(() => {
            allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        activeTab='Game Development'
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={jaydeepExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default JaydeepSanghani